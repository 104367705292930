<template>
  <div>
    <v-card
      class="gift-card default--text"
      :class="mode"
      :flat="mode == 'checkout'"
    >
      <v-row no-gutters>
        <v-col :cols="mode == 'full' ? 12 : 2" :sm="mode == 'full' ? 3 : 1">
          <v-img
            v-if="src"
            :src="src"
            :alt="`Coupon ${name}`"
            class="rounded-sm"
          />
          <div
            v-else
            class="certificate-card d-flex flex-column justify-space-around align-center"
            :class="cssClass"
            @click.prevent.stop="handleClick"
            outlined
          >
            <span class="icon-circle">
              <v-icon>{{ icon }}</v-icon>
            </span>
          </div>
        </v-col>

        <v-col
          :cols="mode == 'full' ? 12 : 10"
          :sm="mode == 'full' ? 9 : 11"
          class="d-flex flex-column body-column"
        >
          <div
            class="text-body-1 font-weight-bold"
            :class="mode == 'full' ? 'text-body-1' : 'text-body-2'"
          >
            {{ name }}
          </div>
          <div
            v-if="!hideEndDate"
            class="expiration text-caption grey lighten-4 my-2"
            v-html="
              `<span class='font-weight-bold text-uppercase'>${$t(
                'checkout.expires'
              )}</span>: ${formatDate(giftCertificate.endDate)}`
            "
          ></div>
          <div class="text-body-2 description" v-html="description"></div>
          <div
            class="text-body-2 mt-2"
            v-if="mode == 'full' && listUsedCertificates.length > 0"
          >
            Utilizzato in questi ordini:
            <v-chip
              v-for="used in listUsedCertificates"
              :key="used.orderId"
              :to="{ name: 'Order', params: { orderId: used.orderId } }"
              class="mr-2"
            >
              <span class="text-caption">#{{ used.orderId }}</span>
            </v-chip>
          </div>
          <div
            class="text-body-2 mt-2"
            v-if="!isAssignable && listAvailableCertificates.length > 0"
          >
            <i>Buoni utilizzabili:</i><br />
            <div
              v-for="available in listAvailableCertificates"
              :key="available.userGiftCertificateId"
              class="py-1 d-flex"
            >
              <span>{{ available.name }}</span>
              <strong v-if="available.amount">
                &nbsp;{{ $n(available.amount, "currency") }}
              </strong>
              <v-spacer />
              <v-btn
                v-if="available.cartId != cart.cartId"
                small
                depressed
                color="primary"
                @click="addUserGift(available.userGiftCertificateId)"
              >
                {{ $t("checkout.giftCodeUseNow") }}
              </v-btn>
              <v-btn
                v-else
                small
                depressed
                color="primary"
                outlined
                @click="remove(available.userGiftCertificateId)"
              >
                {{ $t("checkout.giftCodeRemove") }}
              </v-btn>
            </div>
          </div>

          <v-spacer />

          <v-card-actions class="pa-0 mt-2 space-between align-center">
            <v-btn
              v-if="mode == 'full' && link"
              outlined
              :large="mode == 'full'"
              :small="mode == 'checkout'"
              depressed
              color="primary"
              :to="link"
            >
              {{ btnLabel }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="isAssignable && userGiftCertificateId != null"
              :large="mode == 'full'"
              :small="mode == 'checkout'"
              depressed
              outlined
              color="primary"
              @click="remove(userGiftCertificateId)"
              >{{ $t("checkout.giftCodeRemove") }}
            </v-btn>
            <v-btn
              v-else-if="isAssignable"
              :large="mode == 'full'"
              :small="mode == 'checkout'"
              depressed
              color="primary"
              @click="add"
              >{{ $t("checkout.giftCodeUseNow") }}</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<style lang="scss">
.gift-card {
  &.checkout {
    border-bottom: 1px solid #707070;
    border-radius: 0px;
    padding-bottom: 4px;
    .body-column {
      padding-left: 8px;
    }
    .certificate-card {
      min-height: 0;
      .v-icon {
        font-size: 20px;
      }
    }
  }
  &.full {
    border-radius: 4px;
    .body-column {
      padding: 20px !important;
    }
    .certificate-card {
      min-height: 100px;
      .v-icon {
        font-size: 40px;
      }
    }
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .expiration {
    width: fit-content;
    padding: 2px;
  }
  .certificate-card {
    background-color: var(--v-primary-lighten2) !important;
    border-color: var(--v-primary-lighten1);
    height: 100%;
    min-height: 200px;
    .icon-circle {
      padding: 10px;
      border: 3px solid var(--v-primary-lighten1);
      border-radius: 50%;
      .v-icon {
        color: var(--v-primary-lighten1);
      }
    }
    .promo-body {
      text-align: center;
      min-width: 100px;
      color: white;
      background-color: var(--v-primary-lighten1);
      border-radius: 25px;
      padding: 5px;
      font-weight: bold;
    }
    .content {
      text-align: center;
    }
    &.primary {
      background-color: var(--v-primary-lighten2) !important;
      border-color: var(--v-primary-lighten1);
      .icon-circle {
        border-color: var(--v-primary-lighten1);
        .v-icon {
          color: var(--v-primary-lighten1);
        }
      }
      .promo-body {
        background-color: var(--v-primary-lighten1);
      }
    }
    &.secondary {
      background-color: var(--v-secondary-lighten1) !important;
      border-color: var(--v-secondary-base);
      .icon-circle {
        border-color: var(--v-secondary-base);
        .v-icon {
          color: var(--v-secondary-base);
        }
      }
      .promo-body {
        color: $text-color;
        background-color: var(--v-secondary-lighten1);
      }
    }
    &.warning {
      background-color: var(--v-warning-lighten3) !important;
      border-color: var(--v-warning);
      .icon-circle {
        border-color: var(--v-warning-lighten1);
        .v-icon {
          color: var(--v-warning-lighten1);
        }
      }
      .promo-body {
        color: $text-color;
        background-color: var(--v-warning-lighten1);
      }
    }
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "GiftCard",
  mixins: [giftCertificate],
  props: {
    mode: {
      type: String,
      default: "full"
    }
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(date).format("D MMMM YYYY");
    }
  }
};
</script>
