<template>
  <v-container v-if="giftCertificates && giftCertificates.length > 0">
    <h4 class="my-4">Scegli i prodotti a cui applicare i tuoi bolloni</h4>
    <div
      v-for="giftCertificate in giftCertificates"
      :key="giftCertificate.giftCertificateId"
    >
      <!-- <div>{{ giftCertificate.name }}</div> -->
      <v-row
        v-for="(userGiftCertificate,
        index) in giftCertificate.userGiftCertificates"
        :key="userGiftCertificate.userGiftCertificateId"
      >
        <v-col cols="2">
          <img :src="giftCertificate.image" height="48px" />
        </v-col>
        <v-col>
          <v-select
            :items="giftCertificate.cartItems"
            v-model="userGiftCertificate.cartItem"
            label="Seleziona il prodotto"
            item-value="cartItemId"
            item-text="product.name"
            :item-disabled="
              item => itemDisabled(item, userGiftCertificate, index)
            "
            return-object
            dense
            @change="item => addGift(userGiftCertificate, item, index)"
          >
            <template v-slot:item="{ item }">
              <div class="selectable-row" style="width:100%;height:100%">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.product.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.product.shortDescr }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn color="primary" @click="apply" :loading="loading">Applica</v-btn>
      </div>
    </div>
  </v-container>
</template>
<style>
.selectable-row {
  width: 100%;
  height: 100%;
}
</style>
<script>
import personalCouponService from "~/service/personalCouponService";

export default {
  name: "GiftProductSelector",
  data() {
    return {
      giftCertificates: [],
      items: [],
      loading: false
    };
  },
  props: {
    giftCertificate: { type: Object, required: true }
  },
  methods: {
    addGift(userGiftCertificate, item, index) {
      // personalCouponService.addGiftCertificate(
      //   userGiftCertificateId,
      //   item.cartItemId
      // );
      this.items[index] = {
        userGiftCertificateId: userGiftCertificate.userGiftCertificateId,
        cartItemId: item.cartItemId,
        index: index
      };
    },
    itemDisabled(item, userGiftCertificate) {
      //conto quante volte è stato messo negli items
      // if (
      //   this.items[index] &&
      //   this.items[index].userGiftCertificateId ==
      //     userGiftCertificate.userGiftCertificateId
      // ) {
      //   return false;
      // } else {
      //   let selected = this.items.filter(
      //     obj => obj.cartItemId == item.cartItemId
      //   );
      //   if (selected.length > 0) {
      //     //check if gift certificate row
      //     let used =
      //       selected?.length >= (item.quantity > 0 ? item.quantity : 1);
      //     return used;
      //   }
      // }

      let selected = this.items.filter(
        obj => obj.cartItemId == item.cartItemId
      );
      if (selected.length > 0) {
        //check if gift certificate row
        if (
          selected.find(
            obj =>
              obj.userGiftCertificateId ==
              userGiftCertificate.userGiftCertificateId
          ) != null
        ) {
          return false;
        } else {
          let used =
            selected?.length >= (item.quantity > 0 ? item.quantity : 1);
          return used;
        }
      }
      return false;
    },
    async apply() {
      this.loading = true;
      let certificates = await personalCouponService.addGiftCertificates(
        this.items
      );
      this.loading = true;
      if (certificates) {
        this.$emit("submit", certificates);
      }
    }
  },
  async mounted() {
    this.giftCertificates = await personalCouponService.getGiftCertificate();
  }
};
</script>
